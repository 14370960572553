import { AnimationData, WixCodeAnimationsHandlers } from 'feature-animations'
import { IPlatformAnimationsAPI, EffectOptionsTypes, BaseEffectOptions } from './animations-types'
import _ from 'lodash'
import { PlatformEnvData } from '@wix/thunderbolt-symbols'

const aliasToEffectName: { [alias: string]: string } = {
	PuffIn: 'DropIn',
	PuffOut: 'PopOut',
	RollIn: 'Reveal',
	RollOut: 'Conceal',
	ZoomIn: 'ExpandIn',
	ZoomOut: 'CollapseOut'
}

const millisToSeconds = (num: number) => num / 1000

type EffectSpecificAnimationDataParamsModifiers = {
	[EffectName in keyof EffectOptionsTypes]: (effectOptions: EffectOptionsTypes[EffectName]) => Partial<AnimationData['params']>
}

type AnimationDataParamsFactory = <EffectName extends keyof EffectOptionsTypes, EffectOptions = EffectOptionsTypes[EffectName] & BaseEffectOptions>(
	effectName: EffectName,
	effectOptions: EffectOptions
) => Partial<AnimationData['params']>

const splitCamelCaseIntoWords = (txt: string) => txt.split(/(?=[A-Z])/)

export const PlatformAnimationsAPI = ({ handlers, platformEnvData }: { handlers: WixCodeAnimationsHandlers; platformEnvData: PlatformEnvData }): IPlatformAnimationsAPI => {
	const animationDataParamsFactory: AnimationDataParamsFactory = (effectName, effectOptions) => {
		const animationDataParams: Partial<AnimationData['params']> = {}

		const effectSpecificDataParamsBuilders: Partial<EffectSpecificAnimationDataParamsModifiers> = {
			arc: ({ direction }) => ({ direction }),
			bounce: ({ direction, intensity }) => ({
				bounce: intensity,
				// topLeft -> top left
				direction: splitCamelCaseIntoWords(direction)
					.join(' ')
					.toLowerCase()
			}),
			flip: ({ direction }) => ({ direction }),
			float: ({ direction }) => ({ direction }),
			fly: ({ direction }) => ({ direction }),
			fold: ({ direction }) => ({ direction }),
			glide: ({ angle, distance }) => ({ angle, distance }),
			roll: ({ direction }) => ({ direction }),
			slide: ({ direction }) => ({ direction }),
			spin: ({ direction, cycles }) => ({ direction, cycles }),
			turn: ({ direction }) => ({ direction })
		}

		if (effectName in effectSpecificDataParamsBuilders) {
			Object.assign(animationDataParams, effectSpecificDataParamsBuilders[effectName]!(effectOptions as any))
		}
		return animationDataParams
	}

	return {
		async runAnimation({ compId, animationDirection, effectName, effectOptions }) {
			if (platformEnvData.window.isSSR) {
				return
			}
			// animation data expects values in seconds while user code api provides milliseconds!
			const duration = millisToSeconds(effectOptions.duration)
			const delay = millisToSeconds(effectOptions.delay)

			// @ts-ignore
			const params = animationDataParamsFactory(effectName, effectOptions)
			const effectAlias = _.capitalize(effectName) + _.capitalize(animationDirection)
			const animationData = {
				duration,
				delay,
				targetId: compId,
				name: aliasToEffectName[effectAlias] || effectAlias,
				params
			}
			return handlers.runAnimation(animationData)
		}
	}
}
